bridgetown-search-form {
  display: flex;
  position: relative;
  margin-top: 5px;
  justify-content: flex-end;

  input {
    padding: 3px 10px;
    font-size: 110%;
    border: 1px solid #EEE;
    border-radius: var(--border-radius);

    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }

  input:focus {
    border-color: var(--tertiary-color);
  }
}

bridgetown-search-results {
  --link-color: var(--highlight-color);
  --text-color: var(--body-color);
  --divider-color: var(--tertiary-color);
  --border-corner-radius: var(--border-radius);
  top: 40px;
  font-size: 110%;
  text-align: left;

  h1 {
    color: var(--highlight-color);
    text-decoration: underline;
  }
}

@media ($mobile-breakpoint) {
  bridgetown-search-form {
    display: block;
    margin: var(--spacing-small) 0;
    padding: 0 var(--spacing-small);
    width: 100%;
    max-width: 100%;

    input {
      width: calc(100% - var(--spacing-small));
    }
  }

  bridgetown-search-results {
    max-width: calc(100% - var(--spacing-small));
  }
}
