.layout-page,
.layout-post {
  main {
    padding: 0 20px;
    max-width: var(--max-small-content-width);
  }

  h1 {
    text-align: center;
  }
}

.label {
  display: inline-block;
  margin-bottom: var(--spacing-tiny);
  padding: 5px 15px;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);
}

.markdown-toc a {
  display: block;
  padding: 2px 5px;
}
