.header {
  position: relative;
  padding: 0 0 20px 0;
  text-align: center;
  height: var(--header-height);
  max-height: var(--header-height);

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 5vh;
    max-height: 62px;
  }

  &:before {
    z-index: 2;

    background-image: url(/images/shape-2.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(180deg);
  }

  &:after {
    z-index: 1;

    background-color: var(--highlight-color);
    background: linear-gradient(180deg, rgb(95, 11, 223) 0%, rgb(104, 73, 251) 100%);
  }

  .container {
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    margin: 0 auto;
    padding: 0 20px;
  }

  .logo {
    position: relative;
    z-index: 1;

    display: inline-block;

    font-size: 80px;
    font-family: serif;
    font-style: italic;

    a {
      display: block;
      color: var(--logo-color);
      text-decoration-thickness: 4px;
    }
  }
}

@media ($mobile-breakpoint) {
  :root {
    --header-height: 115px;
  }

  .header {
    padding: 20px 0;

    &:before {
      height: 20px;
      max-height: 50px;
      background-image: none;
      background-color: var(--highlight-color);
    }

    &:after {
      display: none;
    }

    .container {
      position: static;
      display: block;
      text-align: left;
    }

    .logo {
      font-size: 50px;
    }
  }
}
