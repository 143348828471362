.home-page {
  font-size: 1.3em;
}

.home-page .container {
  margin: 0 auto;
  max-width: var(--max-small-content-width);
}

.home-page h1,
.home-page h2 {
  margin-bottom: 40px;
  font-size: 2.4em;
  text-align: center;
}

.home-section {
  --spacing-y: 80px;
  padding: var(--spacing-y) 20px;
}

.home-section:first-child {
  padding-top: 40px;
}

.home-section pre {
  margin-bottom: 0;
}

.hero-section p {
  text-align: center;
}

.dark-section {
  --shape-height: 50px;
  padding-top: calc(var(--shape-height) + var(--spacing-y));
  position: relative;
  background-color: var(--secondary-color);
}

.dark-section:before,
.dark-section:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--shape-height);
}

.dark-section:before {
  z-index: 2;

  background-image: url(/images/shape-3.svg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(180deg);
}

.dark-section:after {
  z-index: 1;

  background-color: var(--secondary-color);
}

.usp-section {
  --icon-size: 70px;

  ul {
    margin-left: 0;
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
    margin-bottom: var(--spacing-normal);

    @media ($mobile-breakpoint) {
      align-items: start;
    }
  }

  .hexagon {
    display: inline-flex;
    align-items: center;
    position: relative;
    top: 4px;
    margin-right: 40px;
    width: calc(var(--icon-size) * 0.645);
    height: var(--icon-size);
    min-width: calc(var(--icon-size) * 0.645);
    min-height: var(--icon-size);
    max-width: calc(var(--icon-size) * 0.645);
    max-height: var(--icon-size);
    background-color: var(--highlight-color);
    border-radius: calc(var(--icon-size) / 10);

    .hexagon-text {
      display: block;
      position: relative;
      width: 100%;
      color: white;
      z-index: 10;
      text-align: center;
      font-size: 2rem;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      width: inherit;
      height: inherit;

      background-color: inherit;
      border-radius: inherit;
    }

    &:before {
      transform: rotate(60deg);
    }
    &:after {
      transform: rotate(-60deg);
    }
  }

  .list-heading {
    display: block;
    font-weight: 400;
    font-size: 1.7rem;
  }
  .list-text {
    display: block;
    font-size: 1.2rem;
  }
}

.columns {
  display: flex;
  gap: var(--spacing-normal);
}

.box {
  padding: var(--spacing-small);
  border-radius: var(--border-radius-tiny);
  background-color: var(--secondary-color);
  box-shadow: 8px 8px 0px var(--tertiary-dark-color);
}

.box a {
  font-size: 1.1em;
}

.box p {
  font-size: 1em;
}

.cta {
  margin-top: var(--spacing-normal);
  text-align: center;
}

.download-link {
  font-size: 0.8em;
}

.commits-box {
  margin-left: var(--spacing-normal);
  padding: var(--spacing-small);
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);

  ul {
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 90%;
    font-style: italic;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  }
}

@media ($mobile-breakpoint) {
  .home-page {
    font-size: 1.1em;
  }

  .home-section {
    --spacing-y: 40px;
  }

  .home-page .hero-section {
    padding-top: var(--spacing-small);
  }

  .hero-section p {
    text-align: left;
  }

  .dark-section {
    --shape-height: 20px;
  }

  .columns {
    flex-direction: column;
  }
}
