.button {
  display: inline-block;
  margin: 0 auto;
  padding: 15px 25px;

  color: #FFF;
  text-decoration: none;
  background-color: var(--highlight-color);
  border-radius: var(--border-radius-tiny);

  &:hover {
    background-color: #3F009F;
    box-shadow: 0 5px 15px -10px rgba(31, 18, 53, 0.6);
  }
}

.button-secondary {
  background-color: var(--tertiary-dark-color);

  &:hover {
    background-color: #842b61;
  }
}
