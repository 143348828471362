.docs-page {
  display: flex;
  column-gap: var(--spacing-normal);
  padding: 0 var(--spacing-small);
  overflow: visible;
}

.docs-navigation {
  position: sticky;
  top: 0;
  flex-grow: 0;
  padding-top: 10px;
  min-width: var(--docs-nav-width);
  max-height: 100vh;
  overflow: auto;
  align-self: flex-start;

  h2 {
    margin-bottom: var(--spacing-tiny);
    padding: 0 10px;
    font-size: 1.5rem;
    text-align: left;
  }

  h3 {
    margin-top: var(--spacing-tiny);
    margin-bottom: var(--spacing-tiny);
    padding: 0 10px;
    font-size: 1.2rem;
  }

  ul,
  ul ul {
    margin-left: 0;
    margin-bottom: var(--spacing-normal);
    list-style: none;
  }

  ul ul ul {
    margin-left: calc(var(--spacing-small) * 1.5);
    margin-bottom: 0;
  }

  ul ul ul li {
    list-style: disc;
  }

  a {
    display: inline-block;
    padding: 2px 10px;
    border-radius: var(--border-radius-tiny);

    &.active {
      color: var(--navigation-active-color);
      font-weight: bold;
      background-color: var(--navigation-active-background);
      text-decoration-color: var(--navigation-active-underline-color);
    }
  }
}

.docs-page .content {
  flex-grow: 1;
  max-width: calc(100% - var(--docs-nav-width) - var(--spacing-normal));
}

@media ($mobile-breakpoint) {
  .docs-page {
    .content {
      max-width: 100%;
    }
  }

  .docs-navigation {
    display: none;
  }
}
