.footer {
  position: relative;
  padding-top: var(--footer-shape-height);
  background-color: var(--highlight-color);
  background: linear-gradient(0deg, rgb(95, 11, 223) 0%, rgb(104, 73, 251) 100%);

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 15vh;
    max-height: var(--footer-shape-height);
  }

  &:before {
    z-index: 2;

    background-image: url(/images/shape-2.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &:after {
    z-index: 1;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--footer-content-height);
    font-size: 1em;
    text-align: center;
    a {
      color: #FFF;

      &:hover {
        text-decoration-color: #FFF;
      }

      &:not(:last-child) {
        margin-right: var(--spacing-small);
      }
    }
  }
}

@media ($mobile-breakpoint) {
  :root {
    --footer-shape-height: 50px;
  }
}
