table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

tr:nth-child(2n) td {
  background-color: var(--secondary-color);
}

th,
td {
  padding: 2px;
}

th {
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid var(--highlight-color);
}

.td-width-50p {
  width: 50%;
}
