.icon {
  position: relative;
  top: 2px;
  width: 20px;
  height: 20px;
  color: inherit;
}

// Colors made using: https://codepen.io/sosuke/pen/Pjoqqp
.icon-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(34deg) brightness(105%) contrast(101%);
}

.icon-orange {
  filter: invert(27%) sepia(56%) saturate(4118%) hue-rotate(4deg) brightness(104%) contrast(106%);
}

.icon-blue {
  filter: invert(33%) sepia(99%) saturate(5161%) hue-rotate(237deg) brightness(86%) contrast(89%);
}

.icon-purple {
  filter: invert(26%) sepia(100%) saturate(7087%) hue-rotate(265deg) brightness(82%) contrast(117%);
}
