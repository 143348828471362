.layout-post time {
  display: block;
  margin-bottom: 20px;
  font-size: 1.0rem;
  text-align: center;
}

.post-item:not(:last-child) {
  margin-bottom: 40px;
}

.post-item time {
  display: block;
  margin-bottom: 5px;
  font-size: 1.0rem;
}
