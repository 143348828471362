code {
  color: var(--code-base-color);
  font-size: 90%;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

pre {
  margin: 0;
  padding: 10px 20px;
  max-width: 100%;
  background-color: var(--code-block-background);
  overflow-x: auto;
  border-radius: var(--border-radius);
}

p code,
li > code {
  display: inline-block;
  padding: 0 4px;

  color: var(--code-inline-color);
  word-wrap: anywhere;
  background-color: var(--code-inline-background);
  border-radius: var(--border-radius-tiny);
}

aside code {
  background-color: var(--code-block-background);
}

.highlighter-rouge:not(:last-child):not(code),
pre:not(:last-child) {
  margin: var(--spacing-small) 0;
}

@media ($mobile-breakpoint) {
  pre {
    margin-left: calc(var(--spacing-small) * -1);
    margin-right: calc(var(--spacing-small) * -1);
    max-width: calc(100% + var(--spacing-small) * 2);
    border-radius: 0;
  }
}

.highlight-custom {
  .color-gray {
    color: var(--code-gray-color);
  }
  .color-red {
    color: var(--code-red-color);
  }
  .color-orange {
    color: var(--code-orange-color);
  }
  .color-yellow {
    color: var(--code-yellow-color);
  }
  .color-blue {
    color: var(--code-blue-color);
  }
  .color-green {
    color: var(--code-green-color);
  }
}

/* Default pygments color scheme */
.highlight {
  .hll { background-color: #ffffcc }
  .c { color: #525252; font-style: italic } /* Comment */
  .err { border: 1px solid #FF0000 } /* Error */
  .k { color: #008000; font-weight: bold } /* Keyword */
  .o { color: #666666 } /* Operator */
  .ch { color: #408080; font-style: italic } /* Comment.Hashbang */
  .cm { color: #408080; font-style: italic } /* Comment.Multiline */
  .cp { color: #BC7A00 } /* Comment.Preproc */
  .cpf { color: #408080; font-style: italic } /* Comment.PreprocFile */
  .c1 { color: #408080; font-style: italic } /* Comment.Single */
  .cs { color: #408080; font-style: italic } /* Comment.Special */
  .gd { color: #A00000 } /* Generic.Deleted */
  .ge { font-style: italic } /* Generic.Emph */
  .gr { color: #FF0000 } /* Generic.Error */
  .gh { color: #000080; font-weight: bold } /* Generic.Heading */
  .gi { color: #00A000 } /* Generic.Inserted */
  .go { color: #888888 } /* Generic.Output */
  .gp { color: #000080; font-weight: bold } /* Generic.Prompt */
  .gs { font-weight: bold } /* Generic.Strong */
  .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
  .gt { color: #0044DD } /* Generic.Traceback */
  .kc { color: #008000; font-weight: bold } /* Keyword.Constant */
  .kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
  .kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
  .kp { color: #008000 } /* Keyword.Pseudo */
  .kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
  .kt { color: #B00040 } /* Keyword.Type */
  .m { color: #666666 } /* Literal.Number */
  .s { color: #BA2121 } /* Literal.String */
  .na { color: #7D9029 } /* Name.Attribute */
  .nb { color: #008000 } /* Name.Builtin */
  .nc { color: #0000FF; font-weight: bold } /* Name.Class */
  .no { color: #880000 } /* Name.Constant */
  .nd { color: #AA22FF } /* Name.Decorator */
  .ni { color: #999999; font-weight: bold } /* Name.Entity */
  .ne { color: #D2413A; font-weight: bold } /* Name.Exception */
  .nf { color: #0000FF } /* Name.Function */
  .nl { color: #A0A000 } /* Name.Label */
  .nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
  .nt { color: #008000; font-weight: bold } /* Name.Tag */
  .nv { color: #19177C } /* Name.Variable */
  .ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
  .w { color: #bbbbbb } /* Text.Whitespace */
  .mb { color: #666666 } /* Literal.Number.Bin */
  .mf { color: #666666 } /* Literal.Number.Float */
  .mh { color: #666666 } /* Literal.Number.Hex */
  .mi { color: #666666 } /* Literal.Number.Integer */
  .mo { color: #666666 } /* Literal.Number.Oct */
  .sa { color: #BA2121 } /* Literal.String.Affix */
  .sb { color: #BA2121 } /* Literal.String.Backtick */
  .sc { color: #BA2121 } /* Literal.String.Char */
  .dl { color: #BA2121 } /* Literal.String.Delimiter */
  .sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
  .s2 { color: #BA2121 } /* Literal.String.Double */
  .se { color: #BB6622; font-weight: bold } /* Literal.String.Escape */
  .sh { color: #BA2121 } /* Literal.String.Heredoc */
  .si { color: #BB6688; font-weight: bold } /* Literal.String.Interpol */
  .sx { color: #008000 } /* Literal.String.Other */
  .sr { color: #BB6688 } /* Literal.String.Regex */
  .s1 { color: #BA2121 } /* Literal.String.Single */
  .ss { color: #19177C } /* Literal.String.Symbol */
  .bp { color: #008000 } /* Name.Builtin.Pseudo */
  .fm { color: #0000FF } /* Name.Function.Magic */
  .vc { color: #19177C } /* Name.Variable.Class */
  .vg { color: #19177C } /* Name.Variable.Global */
  .vi { color: #19177C } /* Name.Variable.Instance */
  .vm { color: #19177C } /* Name.Variable.Magic */
  .il { color: #666666 } /* Literal.Number.Integer.Long */
}
