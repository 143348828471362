.navigation {
  #menu-open {
    display: none;
  }

  .menu-toggle {
    display: none;
    position: absolute;
    top: 14px;
    right: 5px;
    padding: 10px;
    cursor: pointer;
  }

  .menu-icon {
    position: relative;
    padding: 5px;
  }

  .menu-icon-inner {
    position: relative;
    top: 50%;
    margin-top: -2px;
    width: 20px;
    height: 2px;
    background-color: var(--highlight-color);
    border-radius: var(--border-radius-tiny);
    transition-property: transform;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: .22s;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: var(--highlight-color);
      border-radius: var(--border-radius-tiny);
    }

    &:before {
      top: -7px;
      transition: top .1s ease-in .25s,opacity .1s ease-in;
    }

    &:after {
      bottom: -7px;
      transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
    }
  }

  #menu-open:checked ~ .navigation-mobile {
    display: block;
  }

  #menu-open:checked + .menu-toggle .menu-icon-inner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(225deg);
  }

  #menu-open:checked + .menu-toggle .menu-icon-inner:before {
    top: 0;
    transition: top .1s ease-out,opacity .1s ease-out .12s;
    opacity: 0;
  }

  #menu-open:checked + .menu-toggle .menu-icon-inner:after {
    bottom: 0;
    transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    transform: rotate(-90deg);
  }
}

@media ($mobile-breakpoint) {
  .navigation {
    &:before {
      content: "";
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;

      height: 20px;
      max-height: 20px;
      background-color: var(--highlight-color);
    }

    .menu-toggle {
      display: block;
      position: absolute;
      top: 40px;
      right: 10px;
      z-index: 4;
    }
  }
}

.navigation-desktop,
.navigation-mobile {
  ul {
    font-size: 1.3rem;
    font-weight: bold;
    list-style-type: none;
  }

  a,
  h2 {
    display: block;
    margin-bottom: 0;
    padding: 5px 15px;

    font-size: inherit;
  }

  h3 {
    margin-top: var(--spacing-tiny);
    margin-bottom: var(--spacing-tiny);
    padding: 0 10px;
    font-size: 1.2rem;
  }

  a {
    color: var(--highlight-color);
    border-radius: var(--border-radius-tiny);

    &:hover {
      position: relative;
      top: 1px;
    }

    &:hover,
    &.active {
      color: var(--navigation-active-color);
      background-color: var(--navigation-active-background);
      text-decoration-color: var(--navigation-active-underline-color);
    }
  }
}

.navigation-desktop {
  > ul {
    display: flex;
    margin: 0;
    padding: 0;

    justify-content: center;
    gap: 0.5rem;
  }
}

.navigation-mobile {
  display: none;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;

  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;

  background-color: var(--body-background);

  ul {
    display: block;
  }

  > ul {
    margin: 0;
  }

  a,
  h2 {
    padding: 5px 20px;
  }

  h2 {
    margin-top: 5px;
  }

  li {
    margin-bottom: 0;
  }

  ul ul {
    margin-left: 20px;
  }
  ul ul ul {
    margin-left: 40px;
    font-size: 90%;
    list-style-type: disc;

    li {
      margin-bottom: 5px;
      padding: 0;
    }

    a,
    h2 {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  ul ul ul ul {
    margin-left: 30px;
  }

  bridgetown-search-form {
    margin: var(--spacing-tiny) 0;
    padding: 0 var(--spacing-tiny) 0 calc(var(--spacing-tiny) * 2);
    max-width: 100%;

    input {
      width: calc(100% - 80px);
    }
  }

  bridgetown-search-results {
    width: calc(100% - 40px);
  }
}

@media ($mobile-breakpoint) {
  .navigation-desktop {
    display: none;
  }
}
